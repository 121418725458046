'use client';

import { TrackingData } from '@growthbook/growthbook-react';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { Vertical } from 'src/constants/Verticals';
import { useAppContext } from 'src/context/app';
import { env } from 'src/env.mjs';
import { page, setAnonymousId, track } from './Analytics';
import EventNames from './EventNames';
import { EventProps } from './EventProps';
import { PlanConfigurationResponse } from 'src/api/Plan/types';

type TrackPageConfig = {
    disabled?: boolean;
};

export const useTrackPage = (
    name: string,
    properties?: EventProps,
    config?: TrackPageConfig,
) => {
    const params = useSearchParams();
    const anonymousId = params?.get('msh_id');
    const { previousRoute } = useAppContext();

    useEffect(() => {
        if (!config?.disabled) {
            const eventProps = {
                ...properties,
            };

            if (previousRoute) {
                eventProps.referrer = env.NEXT_PUBLIC_BASE_PATH + previousRoute;
            }

            if (anonymousId) {
                setAnonymousId(anonymousId);
            }

            page(name, '', eventProps);
        }
    }, [config?.disabled]);
};

type TrackPageProps = {
    name: string;
    eventProps?: EventProps;
};

export const TrackPage = ({ name, eventProps }: TrackPageProps) => {
    useTrackPage(name, eventProps);

    return null;
};

export function GrowthbookTracking({ data }: { data: TrackingData[] }) {
    useEffect(() => {
        data.forEach(({ experiment, result }) => {
            track(EventNames.EXPERIMENT_VIEWED, {
                experimentId: experiment.key,
                variationId: result.key,
            });
        });
    }, [data]);

    return null;
}

/* Generic click tracking event */
export const trackClick = (eventName: string, eventProps: EventProps) => {
    track(eventName, eventProps);
};

export const trackStartMyFreeConsultClick = (properties: EventProps) => {
    track(EventNames.BUTTON_START_MY_FREE_CONSULT_CLICKED, properties);
};

export const trackLearnMoreClick = (vertical: Vertical) => {
    track(EventNames.BUTTON_LEARN_MORE_CLICKED, {
        vertical: vertical,
    });
};

export const trackGetTheGuideClick = (vertical: Vertical) => {
    track(EventNames.BUTTON_GET_THE_GUIDE_CLICKED, {
        vertical: vertical,
    });
};

export const trackSeeIfYoureEligible = (properties: EventProps) => {
    track(EventNames.BUTTON_SEE_IF_YOURE_ELIGIBLE, properties);
};

export const trackStartQuiz = (vertical: Vertical, properties?: EventProps) => {
    track(EventNames.BUTTON_START_QUIZ, {
        vertical: vertical,
        ...properties,
    });
};

export const trackTabClicked = (tab: string, vertical?: Vertical) => {
    track(EventNames.TAB_CLICKED, {
        vertical: vertical,
        tab: tab,
    });
};

export const trackSalesCompleted = (
    quizToken: string,
    paymentType: string,
    selectedPlanId: string,
    selectedAddOnIds: string[],
    planConfiguration: PlanConfigurationResponse['configuration'],
    vertical: Vertical,
) => {
    track(EventNames.SALES_COMPLETED, {
        planId: selectedPlanId,
        addon_ids: selectedAddOnIds,
        quiz_token: quizToken,
        vertical,
        value: planConfiguration.discountedPrice.toFixed(2),
        payment_method: paymentType,
    });
};

import { getAnonymousUserId } from '@get-mosh/msh-shared';
import { useEffect, useState } from 'react';
import Routes from 'src/constants/Routes';

const useMoshyUrl = (
    source: string,
    medium: string,
    campaign: string,
    content: string,
    term: string,
) => {
    const [moshyUrl, setMoshyUrl] = useState<string | null>(null);

    useEffect(() => {
        const MOSHY_URL = new URL(Routes.MOSHY_HOME);

        MOSHY_URL.searchParams.append('utm_source', source);
        MOSHY_URL.searchParams.append('utm_medium', medium);
        MOSHY_URL.searchParams.append('utm_campaign', campaign);
        MOSHY_URL.searchParams.append('utm_content', content);
        MOSHY_URL.searchParams.append('utm_term', term);

        const userId = getAnonymousUserId();

        // passes the anonymous user id to moshy for tracking
        if (userId) {
            MOSHY_URL.searchParams.append('msh_id', userId);
        }

        setMoshyUrl(MOSHY_URL.toString());
    }, [source, medium, campaign, content, term]);

    return moshyUrl || Routes.MOSHY_HOME;
};

export default useMoshyUrl;

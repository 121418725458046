'use client';

import EventNames from 'src/analytics/EventNames';
import { trackClick } from 'src/analytics/Events';
import Routes from 'src/constants/Routes';
import { Button, useSessionActive } from '@get-mosh/msh-shared';

type LoginLinkProps = {
    className?: string;
    button?: boolean;
};

const LoginLink = ({ className, button }: LoginLinkProps) => {
    const sessionActive = useSessionActive();

    const linkLabel = sessionActive ? 'My account' : 'Login';
    const linkPath = sessionActive
        ? Routes.NOTIFICATIONS
        : Routes.USER_SIGN_IN();

    const handleLoginClick = () => {
        trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
            label: 'Login',
            destination_url: linkPath,
        });
    };

    return button ? (
        <Button
            className={className}
            href={linkPath}
            onClick={handleLoginClick}
            title={linkLabel}
            variant="secondary"
            as="a"
        >
            {linkLabel}
        </Button>
    ) : (
        <a
            className={className}
            href={linkPath}
            onClick={handleLoginClick}
            title={linkLabel}
        >
            {linkLabel}
        </a>
    );
};

export default LoginLink;

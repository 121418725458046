'use client';

import MainNavigation from '../MainNavigation/MainNavigation';
import MoshLogo from '../../MoshLogo/MoshLogo';
import Headroom from 'headroom.js';
import styles from './header.module.scss';
import SideNavigation from '../SideNavigation/SideNavigation';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, TopBar } from '@get-mosh/msh-shared';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import Routes from 'src/constants/Routes';
import common from 'src/data/common.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import commonData from 'src/data/common.json';
import useMoshyUrl from 'src/hooks/useMoshyUrl';

const headerData = {
    mainNavigation: {
        items: [
            {
                label: 'What we treat',
                content: [
                    {
                        label: 'Hair loss',
                        path: '/hair-loss',
                        icon: '/images/icons/man-with-glasses.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Erectile dysfunction',
                        path: '/erectile-dysfunction',
                        icon: '/images/icons/eggplant.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Premature ejaculation',
                        path: '/premature-ejaculation',
                        icon: '/images/icons/watch.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Mental health',
                        path: '/mental-health',
                        icon: '/images/icons/doctor-ipad.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Weight loss',
                        path: '/weight-loss',
                        icon: '/images/icons/dietitian-weight-loss.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Enlarged Prostate',
                        path: '/prostate',
                        icon: '/images/icons/prostate.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Skin care',
                        path: '/skin',
                        icon: '/images/icons/hand-squeezing-cream.svg',
                        width: 48,
                        height: 46,
                    },
                    {
                        label: 'Health store',
                        path: '/non-prescription',
                        icon: '/images/icons/treatment-box-package-mosh.png',
                        width: 48,
                        height: 46,
                    },
                ],
            },
            {
                label: 'Pricing',
                url: '/pricing',
                content: null,
                className: 'hidden lg:block',
            },
            {
                label: 'Our practitioners',
                url: '/our-doctors',
                content: null,
                className: 'hidden md:block',
            },
        ],
    },
    sideNavigation: {
        links: [
            {
                component: 'GetStarted',
                label: common.getStartedNow,
                url: Routes.QUIZZES,
                className: 'side-nav--get-started hidden xl:flex',
            },
            {
                component: 'LoginLink',
                className: 'hidden md:flex',
                url: '',
                label: '',
            },
        ],
        items: [
            {
                label: 'How it works',
                url: '/how-it-works',
                show: '',
            },
            {
                label: 'Pricing',
                icon: '',
                url: '/pricing',
                show: 'lg:hidden',
            },
            {
                label: 'Our Practitioners',
                icon: '',
                url: '/our-doctors',
                show: 'block md:hidden',
            },
            {
                label: 'F.A.Q.',
                icon: '',
                url: Routes.SUPPORT_FAQ,
                show: '',
                target: '_blank' as const,
            },
            {
                label: 'Contact',
                icon: '',
                url: '/contact',
                show: '',
            },
            {
                label: 'About Mosh',
                icon: '',
                url: '/about-us',
                show: '',
            },
        ],
    },
};

type HeaderProps = {
    variant?:
        | 'logo-only'
        | 'logo-left'
        | 'default'
        | 'Logo'
        | 'Logo and CTA'
        | 'Logo centered';
    darkMode?: boolean;
    sticky?: boolean;
    mobileCta?: boolean;
    webCta?: boolean;
    ctaText?: string | null;
    ctaLink?: string;
    isPrismic?: boolean;
    logoLinkHome?: boolean;
};

const logoVariants = [
    'logo-only',
    'logo-left',
    'Logo',
    'Logo centered',
    'Logo and CTA',
];

const Header = ({
    variant = 'default',
    darkMode = false,
    sticky = false,
    mobileCta = false,
    webCta = false,
    ctaText,
    ctaLink,
    isPrismic = false,
    logoLinkHome = true,
}: HeaderProps) => {
    const [topBarHeight, setTopBarHeight] = useState<number | undefined>(0);
    const navigationRef = useRef<HTMLDivElement | null>(null);
    const pathname = usePathname();
    const moshyUrl = useMoshyUrl(
        'Mosh',
        'website-banner',
        'Mosh_Website_Banner-Moshy',
        'moshy-banner',
        'sister-brand-moshy',
    );

    useEffect(() => {
        const containerEl = document.querySelector(
            '[data-header="navigation"]',
        );
        const setContainerOffsetValue = () => {
            if (containerEl) {
                document
                    .querySelector('html')
                    ?.style.setProperty(
                        '--header-navigation-offset',
                        getComputedStyle(containerEl).marginLeft,
                    );
            }
        };
        setContainerOffsetValue();
        window.addEventListener('resize', () => {
            setContainerOffsetValue();
        });

        const navigationHeight = navigationRef.current?.clientHeight;
        document
            .querySelector('html')
            ?.style.setProperty('--header-height', navigationHeight + 'px');

        const header = document.getElementById('headroom');

        if (!header || !topBarHeight || !navigationHeight) {
            return;
        }

        const headroom = new Headroom(header, {
            offset: topBarHeight + navigationHeight,
        });
        // initialise
        headroom.init();
    }, [topBarHeight]);

    const onLinkClick = (label: string, url: string) => {
        trackClick(EventNames.TOP_BANNER_CLICKED, {
            label,
            destination_url: url,
        });
    };

    const topBarItems = [
        pathname === Routes.HOME
            ? {
                  content: (
                      <a
                          className="flex flex-row items-center gap-2 font-demi"
                          href={moshyUrl || ''}
                          onClick={() =>
                              onLinkClick(
                                  'Check out our sister brand Moshy!',
                                  moshyUrl || '',
                              )
                          }
                      >
                          Check out our sister brand Moshy!
                          <FontAwesomeIcon icon={faArrowRightLong} />
                      </a>
                  ) as ReactNode,
                  screen: 'all' as const,
              }
            : {
                  content: (
                      <b>
                          Free shipping | Certified Aussie Practitioners | 100%
                          online
                      </b>
                  ) as ReactNode,

                  screen: 'all' as const,
              },
    ];

    return (
        <header
            id="mainHeader"
            className={classNames(styles['header'], {
                '!bg-green-dark': darkMode,
                '!sticky top-0 z-50': sticky,
            })}
            data-header="container"
            data-variant={variant}
        >
            {variant === 'logo-only' || isPrismic ? null : (
                <TopBar
                    variant={Routes.HOME === pathname ? 'secondary' : 'primary'}
                    onResize={(height) => setTopBarHeight(height)}
                    items={topBarItems}
                />
            )}
            <div className={styles['wrapper']}>
                <section
                    id="headroom"
                    className={classNames(
                        'left-0 right-0 top-0 z-10 bg-white transition-transform duration-200',
                        {
                            '!bg-transparent': darkMode,
                            [styles['header--navigation']]: !sticky,
                        },
                    )}
                    ref={navigationRef}
                >
                    <div
                        className={classNames(
                            'flex items-center p-4 md:container',
                            {
                                'justify-center':
                                    variant === 'logo-only' ||
                                    variant === 'Logo centered',
                                'justify-start':
                                    variant === 'logo-left' ||
                                    variant === 'Logo',
                                'justify-between':
                                    variant === 'Logo and CTA' ||
                                    webCta ||
                                    mobileCta,
                            },
                        )}
                        data-header="navigation"
                    >
                        {logoVariants.includes(variant) ? (
                            <>
                                <MoshLogo
                                    link={
                                        logoLinkHome ? Routes.HOME : undefined
                                    }
                                    variant={darkMode ? 'light' : 'dark'}
                                    label="Home"
                                />
                                {variant == 'Logo and CTA' ? (
                                    <Button
                                        as="a"
                                        href={ctaLink || Routes.QUIZZES}
                                        text={
                                            ctaText || commonData.getStartedNow
                                        }
                                        className={classNames('!w-auto', {
                                            'hidden md:flex':
                                                mobileCta == false,
                                            'md:hidden': webCta == false,
                                        })}
                                        onClick={() => {
                                            trackClick(
                                                EventNames.BUTTON_CLICKED,
                                                {
                                                    link: ctaLink || '#',
                                                    label: ctaText || '',
                                                    location: 'header',
                                                    variation: variant,
                                                },
                                            );
                                        }}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <>
                                <MoshLogo
                                    link={Routes.HOME}
                                    variant="dark"
                                    label="Home"
                                />
                                <MainNavigation
                                    data={headerData.mainNavigation}
                                />
                                <SideNavigation
                                    items={headerData.sideNavigation.items}
                                    links={headerData.sideNavigation.links}
                                />
                            </>
                        )}
                    </div>
                </section>
            </div>
        </header>
    );
};

export default Header;

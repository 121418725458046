import { formatKebab } from '@get-mosh/msh-shared';
import { env } from 'src/env.mjs';
import { Vertical } from './Verticals';

const moshUserPortalPath = env.NEXT_PUBLIC_USER_PORTAL_PATH;

export default {
    // Home
    HOME: '/',
    USER_SIGN_IN: (redirectUrl?: string) =>
        redirectUrl
            ? `/login?redirect_url=${encodeURIComponent(redirectUrl)}`
            : `/login`,
    NOTIFICATIONS: `${moshUserPortalPath}/notifications`,
    QUIZZES: '/quizzes',
    HOW_IT_WORKS: '/how-it-works',
    ERROR: '/error',
    CREATE_ACCOUNT: '/create-account',
    VERIFY_EMAIL: '/verify-email',
    LOGIN: '/login',
    FORGOT_PASSWORD: `${moshUserPortalPath}/users/password/new`,
    WELCOME: '/create-account/welcome',
    QUIZ_CONSULT: '/quiz_consult',
    QUIZ_CONSULT_BOOKING: '/quiz_consult/booking',
    MEDICAL_SUPPORT: '/medical-support',

    CHECKOUT: (vertical: Vertical, quizToken: string) =>
        `/checkout/${vertical.toLowerCase().replace(' ', '-')}?quiz_token=${quizToken}`,

    // Sex
    SEX_INITIAL_QUIZ: '/initial/sex',
    SEX_RENEWAL_QUIZ: '/renewal/sex',
    ED_LANDING_PAGE: '/erectile-dysfunction',
    PE_LANDING_PAGE: '/premature-ejaculation',

    // Mental health
    MENTAL_HEALTH_LANDING_PAGE: '/mental-health',
    MENTAL_HEALTH_INITIAL_QUIZ: '/quizzes/mental_health_quiz',
    MENTAL_HEALTH_RENEWAL_QUIZ: '/renewal/mental_health',
    BOOK_MENTAL_HEALTH: '/booking/mental-health',
    BOOK_RENEWAL: '/booking/np/renewal',
    MENTAL_HEALTH_CARE_PLAN: '/mental-health/mhcp',

    // Weight loss
    WEIGHT_LOSS_LANDING_PAGE: '/weight-loss',
    WEIGHT_LOSS_INITIAL_QUIZ: '/initial/weight_loss',
    WEIGHT_LOSS_RENEWAL_QUIZ: '/renewal/weight_loss',
    WEIGHT_LOSS_PROGRAMS: '/weight-loss/weight-loss-programs',
    WEIGHT_LOSS_MEAL_PLAN: '/weight-loss/meal-plan-for-weight-loss',
    WEIGHT_LOSS_TREATMENT_PLAN: '/wl_treatment_plan',
    WEIGHT_LOSS_CHECKOUT: (quizToken?: string) =>
        quizToken ? `/wl_checkout?quiz_token=${quizToken}` : `/wl_checkout`,

    // Hair
    HAIR_LOSS_LANDING_PAGE: '/hair-loss',
    HAIR_LOSS_INITIAL_QUIZ: '/initial/hair',
    HAIR_LOSS_RENEWAL_QUIZ: '/renewal/hair',
    HAIR_GROWTH: '/hair-growth',
    HAIR_LOSS_TREATMENT: '/hair-loss/hair-loss-ultimate-guide',

    // Skin
    SKIN_LANDING_PAGE: '/skin',
    SKIN_INITIAL_QUIZ: '/initial/skin',
    SKIN_RENEWAL_QUIZ: '/renewal/skin',
    SKIN_ANTI_AGEING: '/skin/anti-ageing',

    // Prostate
    PROSTATE_INITIAL_QUIZ: '/initial/prostate',
    PROSTATE_RENEWAL_QUIZ: '/renewal/prostate',
    PROSTATE_SYMPTOMS_QUIZ: '/symptoms/prostate',
    PROSTATE_GUIDE: '/prostate/benign-prostatic-hyperplasia-guide',
    PROSTATE_LANDING_PAGE: '/prostate',
    PROSTATE_TREATMENT_PLAN: '/prostate-treatment-plan',
    PROSTATE_TREATMENT_PLAN_CLOSED: '/prostate-treatment-plan/closed',
    PROSTATE_TREATMENT_PLAN_NEXT: '/prostate-treatment-plan/next',
    PROSTATE_BOOKING: (quizToken: string) =>
        `/booking/time_select?quiz_token=${quizToken}&quizType=Prostate`,

    // Diabetes
    DIABETES_LANDING_PAGE: '/diabetes',
    DIABETES_INITIAL_QUIZ: '/initial/diabetes',
    DIABETES_RENEWAL_QUIZ: '/renewal/diabetes',
    DIABETES_BOOKING: (quizToken: string) =>
        `/quiz_consult?quiz_token=${quizToken}`,
    DIABETES_TREATMENT_PLAN: '/review-plan/diabetes',
    DIABETES_CHECKOUT: (quizToken?: string) =>
        quizToken
            ? `/checkout/diabetes?quiz_token=${quizToken}`
            : `/checkout/diabetes`,
    DIETITIAN_BOOKING: '/booking/dietitian',

    // Non-prescription/OTC
    NON_PRESCRIPTION: '/non-prescription',
    LIBIDO_VITAMIN: '/non-prescription/energy-libido-vitamin',
    HAIR_STARTER_KIT: '/non-prescription/hair-starter-kit',
    HAIR_LIBIDO_BUNDLE: '/non-prescription/hair-libido-bundle',
    KEEP_VITAMIN: '/non-prescription/keep-vitamin',
    MINOXIDIL: '/non-prescription/minoxidil',
    SHAMPOO_CONDITIONER: '/non-prescription/shampoo-conditioner',
    SLEEP_VITAMIN: '/non-prescription/sleep-vitamin',
    WEIGHT_LOSS_SHAKE: '/non-prescription/weight-loss-shake',
    WEIGHT_LOSS_SHAKE_ONE: '/non-prescription/weight-loss-shake-one',
    WEIGHT_LOSS_SHAKE_ONE_ONE_TIME:
        '/non-prescription/weight-loss-shake-one-one-time',
    WEIGHT_LOSS_SHAKE_ONE_TIME: '/non-prescription/weight-loss-shake-one-time',

    // Misc
    TERMS_OF_USE: '/terms',
    PRIVACY_POLICY: '/privacy',
    ABOUT_US: '/about-us',
    CONTACT_US: '/contact',
    FAQ: '/faq',
    OUR_DOCTORS: '/our-doctors',
    PRESS: '/press',
    PRICING: '/pricing',
    RESULTS: '/results',
    BLOG: '/blog',
    CAREERS: '/careers',
    OUR_THERAPISTS: '/our-therapists',
    THERAPY: '/therapy',
    SITEMAP: '/sitemap',

    // External
    REVIEWS: 'https://au.trustpilot.com/review/getmosh.com.au',
    HAIR_TRANSPLANT_REQUEST_A_QUOTE:
        'https://www.hairdoctors.com.au/free-hair-transplant-quote',
    SUPPORT_FAQ: 'https://support.getmosh.com.au/mosh',
    FACEBOOK_GROUP: 'https://www.facebook.com/groups/menshealthstuff',
    // specialty-27 is the id for GP
    HOTDOC_GPS_NEAR_ME: (suburb: string, state: string, postcode: string) =>
        `https://www.hotdoc.com.au/search?filters=specialty-27&in=${formatKebab(suburb)}-${state}-${postcode}`,
    HOTDOC_GPS: `https://www.hotdoc.com.au/search?filters=specialty-27`,
    MOSHY_HOME: env.NEXT_PUBLIC_MOSHY_BASE_PATH,
    SLICE_SIMULATOR: '/prismic/slice-simulator',
} as const;
